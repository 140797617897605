import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import Application from "../components/flightbooking/application";
import Footer from "../components/footer";
import Metatags from "../components/metatags";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Metatags title="Flight seat picker | May 14 | CSS Grid a day" description="An example of using CSS grid every day, from May 1–31st, 2019." thumbnail="https://cssgrid31.brett.cool/opengraph/may-14.png" url="https://cssgrid31.brett.cool" pathname="/may-14" mdxType="Metatags" />
    <Application mdxType="Application" />
    <Footer date={14} prev={true} next={true} mdxType="Footer">
      <p>{`Unlike booking flights, creating this flight seat picker was fun, and a good opportunity to practice setting state in React.`}</p>
      <p>{`I particularly like how easy it is to declare a responsive layout by changing a grid’s template columns, rows, and areas for certain media queries—it’s pretty easy to work with.`}</p>
    </Footer>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      