import React from "react"
import SeatStyle from "./seat.module.scss"

function Ailse(props) {
  return (
    <div className={SeatStyle.ailse}/>
  )
}

export default Ailse
